import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl sign sign`}</strong>{` -- sign a message using Ed25519`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl sign sign <priv-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl sign sign`}</strong>{` signs a message m using the signer's private
key.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_sign function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl sign`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--raw`}</strong>{`
Do not base64 encode output`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      